@import '../../../../style/media-variables';

.vas-card {
  width: auto;
  max-width: 26.81rem;
  height: toRem(621px);
  max-height: 45.375rem;
  padding: 2.69rem 2.06rem 2.50rem 2.69rem;
  display: flex;
  background: linear-gradient(60.61deg, #FFFFFF 62.23%, #056EB5 217.02%);
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #DFDFDF;
  box-shadow: 0px 2px 16px 0px #0528501A;
  border-radius: 1.25rem;
  color: var(--textPrimary);
  font-family: var(--font-family);
  position: relative;

  @include sm-max(){
    max-width: toRem(310px);
    height: toRem(487px);
    padding: 1.56rem 3.37rem 1.69rem 1.62rem
  }

  @include md-max(){
    height: auto;
  }

  .jcms-html-title {
    font-weight: 700;
    font-size: toRem(30px);
    margin: 0!important;

    @include sm-max(){
      font-size: toRem(20px);
    }
  }

  .jcms-html-subtitle {
    font-weight: 400;
    font-size: toRem(14px);
  }

  .jcms-html-text{
    font-weight: 400;
    font-size: toRem(15px);
    min-height: toRem(110px);
  }

  .vas-price-container{
    display: flex;
    flex-direction: row;
    gap: 0.4rem;
    align-items: baseline;
    margin-top: 1rem;

    .price {
      font-family: var(--font-family);
      font-size: toRem(30px);
      font-weight: 700;
    }
    .cronDescription {
      font-family: var(--font-family);
      font-size: toRem(14px);
      font-weight: 400;
    }
  }

  .vas-cta-purchased, .vas-cta-purchase {
    width: 100%;
    margin: 1rem 0 2rem 0;
    button{
      width: 100%;
      max-width: toRem(353px);
      height: toRem(48px);
      background: var(--primary);
      color: var(--textInvert);
      border: unset;
      span {
        font-family: var(--font-family);
        font-size: toRem(20px);
        font-weight: 600;
      }
      @include sm-max(){
        width: toRem(265px);
        height: toRem(44px);
        font-size: toRem(16px);
      }
      &:hover {
        background: var(--primary)!important;
        border: unset;
      }
    }
  }

  .vas-cta-purchased {
    button{
      background: var(--disabled)!important;
      border: unset!important;
      span {
        font-family: var(--font-family);
        color: var(--textPrimary)!important;
      }
      &:hover {
        background: var(--disabled)!important;
        border: unset!important;
        span {
          color: var(--textPrimary)!important;
        }
      }
    }
  }

  .vas-included{
    height: toRem(200px);
    overflow-y: auto;
    margin-top: 1rem;
    @include sm-max(){
      height: toRem(140px);
      margin-top: 0;
      padding-bottom: 1rem;
    }
    .jcms-html-title {
      font-weight: 600;
      font-size: toRem(20px);
      @include sm-max(){
        font-size: toRem(16px);
      }
      @include md-max(){
        font-size: toRem(18px);
      }
    }


    .jcms-html-text{
      line-height: toRem(30px);
      span {
        font-weight: 400;
        font-size: toRem(20px);
        @include sm-max(){
          font-size: toRem(14px);
        }
        @include md-max(){
          font-size: toRem(16px);
        }
      }

    }
  }

  .vas-card-more-info {
    position: absolute;
    bottom: 1.3125rem;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    @include sm-max(){
      position: unset;
      margin-top: toRem(16px);

    }
    @include md-max(){
      margin-top: toRem(16px);
      position: unset;
    }
    button{
      width: toRem(205px);
      height: toRem(48px);
      background: var(--white)!important;
      @include sm-max(){
        width: toRem(190px);
        height: toRem(44px);
        font-size: toRem(16px);
      }
      span{
        font-family: var(--font-family);
        color: var(--textPrimary)!important;
        font-size: toRem(20px);
        font-weight: 600;
      }
    }
  }
}