.jpage-context-cart {    
    .jsection-context-cart {
        min-height: 65vh;
        .ant-row-context-cart {
            gap: var(--static-margin);
            align-items: start;
            .cart-isempty {
                font-size: var(--section-text);
            }
        }
    }
}

.cart-container-desktop {
    width: 100%;
}

@media (min-width: 1378px) {
    .cart-container-desktop {
        width: 25rem;
    }
}