.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: var(--primary);
  min-height: 100vh;
  justify-content: center;

  @media (max-width: 962px) {
  }

  .imgError {
    max-width: 100%;
    max-height: 100%;
  }

  .lblContainer{
    position: absolute;
    bottom: 10rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    @media (max-width: 768px) {
      position: unset;
      text-align: center;
    }

    .jcms-html-title span{
      font-size: 2.5rem;
      color: var(--textInvert);
      font-family:var(--font-family);
      font-weight: bold;
    }
    .jcms-html-text span{
      font-size: 1.5rem;
      color: var(--textInvert);
      font-family:var(--font-family);
    }
  }
}