@import '../../../../../../style/media-variables.scss';

.cart-product-item-preview {
    display: flex;
    gap: var(--static-halfmargin);
    width: 100%;
    height: 64px;

    .cart-product-item-price {
        align-self: flex-start;
    }

    .cart-product-item-info {
        flex: auto;

        .cart-product-item-info-brands {
            font-size: toRem(14px);
        }

        .jcms-html-title {
            font-size: toRem(14px);
            margin-bottom: 0;

            span {
                font-weight: normal;
                margin-bottom: 0;
            }
        }
    }
    .cart-product-item-image {
        border: 1px solid #DFDFDF; //var(--tertiary-light);
        border-radius: 7px;
        overflow: hidden;
        width: 64px;

        .jcms-image {
            display: block;
            height: 100%;

            >img {
                &:not(.jcms-image-placeholder) {
                    padding: calc(var(--static-halfmargin) / 2);
                    object-fit: scale-down;
                }
            }
        }
    }

    .cart-product-item-info-actions {
        .jcms-cta button.jcms-cta-type-wrapless span {
            color: var(--primary-light);
            font-size: toRem(18px);
            font-weight: 600;
            text-decoration: none;
        }
    }
    .cart-product-item-price {
        .item-value {
            padding: 0;
            &::before {
                background: transparent;
            }
            .item-value-content {
                font-weight: 600;
                color: var(--textPrimary)
            }
        }
    }
}