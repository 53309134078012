@import '../../../../style/media-variables';

.card-subscripition-purchased {
  background: var(--background-silver);
  height: auto;
  min-height: toRem(469px);
  border-radius: toRem(20px);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: toRem(1376px);
  display: flex;
  flex-direction: column;
  padding: 2.94rem 2.06rem 2.62rem 2.56rem;

  @include md-max() {
    padding: 1.5rem;
    flex-direction: column;
  }

  .card-subscripition-purchased-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;

    @include md-max() {
      flex-direction: column;
    }

    .card-leftSide {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      @include md-max() {
        width: 100%;
        padding: 1.5rem;
      }

      span.jcms-html-text {
        text-transform: uppercase;
        color: var(--textPrimary);
        font-size: toRem(14px);
        font-weight: 400;
        font-family: var(--font-family);
        @include md-max() {
          font-size: toRem(12px);
        }
      }

      h1.jcms-html-title {
        align-items: flex-start;
        text-align: start;
        font-size: toRem(30px);
        font-weight: 700;
        color: var(--textPrimary);
        font-family: var(--font-family);
        margin: 0;
        @include md-max() {
          font-size: toRem(20px);
        }

      }

      span.jcms-html-subtitle {
        align-items: flex-start;
        text-align: start;
        color: var(--textPrimary);
        font-size: toRem(18px);
        font-weight: 400;
        @include md-max() {
          font-size: toRem(14px);
        }
      }

      .btn-container {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 1rem;
        margin-top: 1rem;

        @include md-max() {
          display: flex;
          justify-content: center;
          margin-top: 2rem;
          align-items: center;
        }

        @include sm-max() {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 2rem;
        }


        .btn-more-info {
          button {
            width: auto;
            min-width: toRem(205px);
            height: toRem(48px);
            background: var(--white);
            @include md-max() {
              min-width: toRem(190px);
              height: toRem(44px);
            }

            span {
              font-family: var(--font-family);
              color: var(--textPrimary) !important;
              font-size: toRem(20px);
              font-weight: 600;
            }

            &:hover {
              background: var(--white);

              span {
                color: var(--textPrimary) !important;
              }
            }
          }
        }

        .btn-catalog {
          button {
            width: auto;
            min-width: toRem(205px);
            height: toRem(48px);
            background: #FF4D4D;
            border: unset;

            span {
              font-family: var(--font-family);
              color: var(--textInvert) !important;
              font-size: toRem(20px);
              font-weight: 600;
            }

            @include md-max() {
              min-width: toRem(190px);
              height: toRem(44px);
            }

            &:hover {
              background: #FF4D4D;

              span {
                color: var(--textInvert) !important;
              }
            }
          }
        }
      }

    }

    .card-rightSide {
      width: 50%;
      height: 100%;
      display: flex;
      gap: 0.5rem;
      flex-direction: column;

      @include md-max() {
        width: 100%;
      }

      h1.jcms-html-title {
        align-items: flex-start;
        text-align: start;
        font-size: 1.25rem;
        font-weight: 600;
        line-height: toRem(22px);
        color: var(--textPrimary);
        font-family: var(--font-family);
        margin: 0;
        @include md-max() {
          font-size: toRem(20px);
        }

      }

      .card-catalog {

        .jcard-container {

          .jcard-header {

            @include sm-max() {
              height: 100%;
              max-width: toRem(121px);
            }
          }

        }

      }
    }
  }
}