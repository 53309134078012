.microapp-container {
    width: 100%;
    /*> .microapp-loader {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(white, 0.7);
        z-index: 10;

        display: flex;
        align-items: center;
        justify-content: center;
    }*/

    > .microapp-loader {
        padding: 48px 12px;
    }
    > .microapp-content {
        opacity: 0;
        transition: .7s ease-in-out opacity;

        &.visible {
            opacity: 1;
        }
    }
}