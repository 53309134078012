
.cart-product-item-mobile {
    gap: var(--static-halfmargin);
    display: flex;
    flex-direction: column;

    .cart-product-item-top-section {
        flex: auto;
        display: flex;

        border-bottom: 1px solid var(--tertiary-light);

        .cart-product-item-info {

            padding: var(--static-halfmargin);
        }
    }

    .cart-product-item-price {
        display: flex;
        justify-content: center;
        .item-value {
            align-self: center;
        }
    }
    .cart-product-item-middle-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 var(--static-halfmargin);
        border-bottom: 1px solid #DFDFDF; //var(--tertiary-light);
        padding-bottom: var(--static-halfmargin);
        align-items: baseline;

        .cart-product-item-quantity {
            width: calc(120px - 1rem);
        }
    }

    .cart-product-item-info-actions {
        display: flex;
        padding: 0 var(--static-halfmargin) var(--static-halfmargin) var(--static-halfmargin);
        justify-content: center;
    }
}