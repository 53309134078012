.profileContainer {
    height: 100%;
    .ant-tabs-nav {
        width: 100%;
    }
    .outlet {
        .jsection-container {
            padding-right: 0;
            padding-top: 0;
        }
    }
}

.iw-legend{
    position: relative;
    margin-bottom: var(--static-margin);
    color: var(--iw-contest-legend-color, var(--primary));
}
