@import '../../../../style/media-variables';

.ant-modal-header{
  border: none;
  .ant-modal-title{
    font-family: var(--font-family);
    font-size: toRem(24px);
    font-weight: 700;
    @include sm-max(){
      font-size: 18px;
    }
  }
}
.ant-modal-body{
  .vas-modal{
    .jcms-vas-sub-panel {
      border-bottom: 1px solid var(--grayDF);

      .jcms-vas-sub-panel-title {
        font-weight: 700;
        font-size: toRem(18px);
        line-height: toRem(36px);
        @include sm-max(){
          font-size: 14px;
        }
      }

      .ant-collapse-header-text{
        font-family: var(--font-family);
        font-weight: 400;
        font-size: toRem(18px);
        line-height: toRem(27px);
        @include sm-max(){
          font-size: 14px;
        }
      }
    }
  }
}