@import "~@jnext/commons/dist/jnext.css";
@import "~@jnext/commons/dist/css-variables.scss";
@import './style/media-variables.scss';
//@import '~antd/dist/antd.css';

body {
  margin: 0;
  color: var(--textPrimary);
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Source Sans Pro', sans-serif;
}

// Added outline on button and anchor tags for accessibility purposes
a, button, .ant-collapse-header,
.ant-tabs-tab-btn {
  &:focus,
  &:focus-visible {
    outline: var(--focus-outline);
  }
}

// Override Ant D error message color by using the color set on the CMS
.ant-form-item-explain-error {
  color: var(--error) !important;
}


.skip {
  color: var(--primary);
  background-color: rgb(255, 255, 255);
  border: 3px solid rgba(19, 110, 248, 0.8);
  outline: rgb(99, 154, 249) solid 0;
  box-shadow: rgba(19, 110, 248, 0.3) 0 0 0 5px;

  &:focus {
    opacity: unset;
    transition: all 0.3s ease 0s;
  }
}

@media only screen and (max-width: 374px) {
  .jsection > .jsection-container .jheadertitle-cta {
    position: unset !important;
  }

  .HeroCardContainer {
    margin: 0 0 !important;
  }

  .navbarContainerRow .leftSide .logo {
    width: 5rem !important;
  }
  .leftPos {
    font-size: toRem(8px) !important;
  }
  .jcms-static-faq-panel-title {
    font-size: 0.75rem !important;
  }
}
.required_disclaimer{
  font-size: 0.75rem !important;
  font-weight: 700;
  display: flex;
  justify-content: center;
  margin: 1rem;
}
