@import './../../../../style/mixins';
@import './../../../../style/media-variables';

.cart-product-item {
    .jbox-row {
        padding: 0!important;
        flex-direction: column;
    }
    .cart-product-item-container{
        overflow: hidden;

        .cart-product-item-info-actions {
            .jcms-cta button.jcms-cta-type-wrapless span {
                color: var(--primary-light);
                font-size: 1.2rem;
                font-weight: 600;
            }
        }

        .cart-product-item-info {
            flex: auto;
            display: flex;
            flex-direction: column;

            .cart-product-item-info-title {
                flex: auto;

                .not-available {
                    color: red;
                    font-size: toRem(12px);
                    margin-bottom: toRem(5px);
                }
            }

        }

        .cart-product-item-image {
            border-right: 1px solid var(--tertiary-light);

            .jcms-image {
                width: 120px;
                display: block;
                height: 100%;
                >img:not(.jcms-image-placeholder) {
                    padding: var(--static-halfmargin);
                    object-fit: scale-down;
                }
            }
        }

        .cart-product-item-quantity {
            .quantityForm{
                gap: 0.5rem;
            }
            .form-style-wrapper .form-compositor .ant-form-item {
                margin: 0;
                margin-bottom: 0!important
            }

        }


    }
}