.cart-preview {
    .cart-preview-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .cart-preview-title {

            color: var(--primary-light);
            font-size: toRem(14px);
            font-weight: 700;
        }

    }

    .jbox-container {
        gap: calc(var(--static-halfmargin) / 2);

        .jbox-row {
            padding: 0 var(--static-halfmargin);

            .cart-summary-total {

                display: flex;
                justify-content: space-between;
                width: 100%;

                .cart-summary-total-label {
                    font-size: toRem(14px);
                    color: var(--textSecondary);

                }

                .item-value {
                    padding: 0;
                    &::before {
                        background: transparent;
                    }

                    .item-value-content {
                        font-weight: 600;
                        color: var(--textPrimary)
                    }
                }
            }
        }

        .jbox-row:first-of-type {
            padding-top: var(--static-halfmargin);
        }

        .jbox-row:last-of-type {
            padding-bottom: var(--static-halfmargin);
        }
    }
}