.cart-product-item-desktop {
    //height: 120px;
    display: flex;
    flex-direction: row;

    >div:not(.cart-product-item-image) {
        padding: var(--static-halfmargin);
    }


    .cart-product-item-info-actions {
        .jcms-cta button.jcms-cta-type-wrapless span {
            text-decoration: none;
        }
    }


    .cart-product-item-price {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 160px;
        align-items: flex-end;

        .item-value {
            width: fit-content;
        }
    }

    .cart-product-item-quantity {
        width: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .form-style-wrapper .form-compositor .ant-form-item {
            margin: 0;
        }
    }
}