.generate-sso-container {
    padding-top: 50px;
}

.sso-link {
    font-size: 30px;
    font-weight: bold;
}

.generating-token {
    padding-bottom: 20px;
}