@import './style/media-variables.scss';

body, html {
  max-width: 100vw;
  //overflow-x: hidden;
}

body {
  //opacity: 0;
  transition: opacity 1s ease-in-out;
  background: var(--background);

  &.show {
    opacity: 1;
  }

}

#root {
  color: var(--black);
}

.App {
  text-align: center;

  .drawer {
    height: 6.25rem;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.ant-modal {

  @include xs-max() {
    top: 20px;
  }
}
.ant-modal-wrap{
  z-index: 1000;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--textInvert);
  font-family: var(--font-family);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.app-spinner-container {
  position: fixed;
  background: rgba(255,255,255,0.2);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-spin {
    opacity: 1;
  }
}

.demo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  height: auto;
  padding: 2rem 0 0 2rem;

  .ant-select{
    width: 100%;
  }

  .title {
    align-self: center;
    color: var(--textPrimary);
    font-family: var(--font-family);
    font-weight: bold;
    line-height: 2.188rem;
    font-size: 2rem;
    margin-bottom: 5rem;
  }

  .btn {
    align-self: center;
    height: 2.7rem;
    width: 100%;
    font-size: 1.25rem;
    border:none;
    margin-top: 5rem;
  }
}
.required-fields{
  font-size: 60%;
  font-weight: bold;
}


.form-compositor-LOGIN{
  form {
    .ant-row{
      .radio-container{
        .ant-col-md-12{
          max-width: 100%;
          flex: 0 0 100%;
        }
      }
    }
  }
}

.navbarContainerRow .centerSide .links .linksGenerate .linkContainer .link {
  display: flex
}

#rootHeaderCustom, #rootFooterCustom {
  width: 100%;
}