@import './../../../../../style/media-variables.scss';

.coreuser-page {
    max-width: 1440px;
    margin: 0 auto!important;
    .jheadertitle {
        >.jcms-html-title {
            span {
                color: var(--textPrimary);
                font-size: var(--section-subtitle);
                font-weight: 700;
            }
        }
    }

    .sidebarTemplateContainer {
        .content {
            .coreuser-content {
                min-height: 21.875rem;
                max-width: 38.75rem;
            }
        }
    }

    .coreuser-content {
        display: flex;
        flex-direction: column;
        padding: 0 0 3rem 0;

        #genericFormPage {
            height: auto;
            max-width: 100%;
        }



    }
}