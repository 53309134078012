.cart-dropdown {
    .cart-dropdown-image {
        height: 24px;
        width: 24px;
        cursor: pointer;
    }

    >.anticon {
        font-size: 24px;
    }
    &__button.ant-btn {
        border: 0;
        box-shadow: none;
        >.anticon.anticon-shopping-cart {
            font-size: 24px;
        }
        &:hover,
        &:focus {
            color: var(--textPrimary);
        }
    }
}