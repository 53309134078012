@import './../../media-variables.scss';
@import './../../mixins';

.HeroSectionContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HeroCardContainer {
    background-color: var(--background-card);
    border-radius: 30px;
    box-shadow: -5px 7px 16px 0 rgba(5, 40, 80, .1);
    position: relative;
    align-items: center;
    display: flex;
    height: 7rem;
    justify-content: space-between;
    gap: 1rem;

    @include sm-max() {
        width: 100%;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        flex-direction: column;
        height: initial;
        gap: calc(var(--static-margin) / 2);
    }

    @include md-min() {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 4rem;
    }

    @include lg-min() {
        margin-top: calc(calc(var(--static-margin) + 66px) * -1);
    }

    .solution-project-image+.userSection {
        margin-left: var(--static-margin);

        @include sm-max() {
            margin-top: calc(var(--static-margin) * 2);
        }
    }

    .avatar {
        width: var(--hero-banner-avatar-size);
        height: var(--hero-banner-avatar-size);
        position: absolute;
        top: -40px;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 3rem;

        @include md-min() {
            text-align: center;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%) translateX(-50%);
            right: initial;
        }

        .ant-avatar-lg {
            width: 100%;
            height: 100%;
            font-size: 3rem;
            line-height: 3.125rem;
        }
    }

    .userSection {
        font-size: var(--hero-banner-user-text-font-size);

        @include sm-max() {
            margin-top: calc(var(--static-margin) * 1);
            margin-left: 0 !important;
            text-align: center;
            margin-bottom: 0;
        }
    }

    .coccarda {
        max-width: 2rem;
        position: absolute;
        left: 1rem;
        z-index: 1;
    }

    .HeroCardContent {
        z-index: 1;
        color: var(--primary);
        line-height: 1.2;
        padding-top: 2.5rem;
        text-align: center;

        @include md-min() {
            padding-top: 0rem;
            text-align: left;
        }

        .clusterId {
            font-weight: bold;
            font-size: 1.5rem;
            margin-bottom: 0;
            text-transform: uppercase;
        }
    }

    .scoreCounterContainer {
        padding: 1rem;
        font-size: var(--hero-banner-wallet-points-font-size);

        @include sm-max() {
            padding: 0.5rem;
        }
    }

    .point {
        font-size: var(--hero-banner-wallet-name-font-size);
    }

    .HeroCardButton {
        width: 100%;
        background: var(--primary);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 0 30px 30px;
        padding: 6px 0px;

        @include md-min() {
            height: 100%;
            width: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0 30px 30px 0;
            padding: initial;
        }

        .goToProfile {
            margin: 0;
            text-align: center;
            cursor: pointer;
            color: var(--textInvert);
            font-family: var(--font-family);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .jcms-cta button.jcms-cta-type-link.jcms-cta-color-default span {
                font-size: var(--hero-banner-cta-font-size);
            }

            .jcms-cta button.jcms-cta-type-link.jcms-cta-color-default span:hover {
                background: none;
                text-decoration: none;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.header-fixed {
    .HeroCardContainer {

        &.fixed,
        &.scrolled {
            position: relative;
            margin-top: 0;
            width: 100%;
            transition: all .5s ease-out;
            height: toRem(84px);
            border-radius: 0 0 30px 30px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &.scrolled {
            transition: all .5s ease-in;
            height: toRem(54px);
        }

        // & .userSection {
        //     width: 100%;
        // }

        & .HeroCardButton {
            position: relative;
            height: 100%;
            border-radius: 0 0 30px 0;
            width: toRem(248px);
        }
    }
}