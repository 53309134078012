@import './../../../../../../style/media-variables.scss';

.cart-preview {
    min-width: 300px;
    .cart-preview-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .cart-preview-header {
            display: flex;
            justify-content: space-between;
            align-content: center;
            align-items: center;

            .cart-preview-title {

                color: var(--primary-light);
                font-size: toRem(14px);
                font-weight: 700;
            }
            .anticon {
                font-size: toRem(16px);                
            }
        }

    }

    .initiatives-list {
        .initiative-row {
            .initiative-name {

            }

        }
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .jbox-container {
        gap: calc(var(--static-halfmargin) / 2);
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
        overflow: visible;

        .jbox-row {
            padding: 0 var(--static-halfmargin);
        }

        .jbox-row:first-of-type {
            padding-top: var(--static-halfmargin);
        }

        .jbox-row:last-of-type {
            padding-bottom: var(--static-halfmargin);
        }
        .jbox-cta {
            margin-top: 0;
            .jcms-cta {
                button {
                    border-radius: 0 0 30px 30px;
                }
            }
        }
    }
}