@import './../../media-variables.scss';
@import './../../mixins';

.MobileHeroSectionContainer{
    background: var(--header-background);
    border-radius: 0 0 30px 30px;
    height: auto;
    padding: toRem(28px) toRem(16px);
    display: flex;
    flex-direction: column;
    justify-content: center;

    .MobileUserContainer{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        border-bottom: toRem(1px) solid var(--grayDF);
        padding: toRem(25px) 0;

        .userSection{
            font-size: toRem(18px);
        }

        .HeroCardButton {
            border-radius: 30px;
            width: auto;
            background: var(--primary);
        }
    }

    .MobileWalletContainer{
        margin-top: toRem(16px);
       .scoreCounterContainer{
           padding: 0 2rem;
          .scoreCounterInnerContainer{
              display: flex;
              flex-direction: column;
              gap: toRem(16px);
              align-items: center;
              .scoreCounterPointsContainer{
                  flex-direction: column-reverse;
                  gap: toRem(10px);
                  .scoreCounterInnerPointsContainer{
                      flex-direction:row;
                      gap: toRem(8px);
                      .solution-project-image{
                          width: toRem(20px);
                          height: toRem(20px);
                      }
                      .points{
                          font-size: toRem(16px)!important;
                      }
                  }
                  .point {
                      font-size: toRem(16px)!important;
                  }
                  .point {
                      font-weight: 600;
                      width: auto;
                  }
              }
          }
       }
    }

}