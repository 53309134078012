@import '../../../../style/media-variables';

.card-subscrpition {
  height: auto;
  min-height: toRem(188px);
  max-width: toRem(1376px);
  display: flex!important;
  flex-direction: row;
  border-radius:toRem(20px);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background: var(--background-gold);
  position: relative;
  padding: 3rem;

  @include md-max() {
    min-height: toRem(311px);
    flex-direction: column;
  }

  .card-leftSide {
    display: flex;
    flex-direction: column;
    width: 50%;

    @include md-max() {
      width: 100%;
    }


    span.jcms-html-text {
      text-transform: uppercase;
      color: var(--textPrimary);
      font-size:  toRem(14px);
      font-weight: 400;
      font-family: var(--font-family);
      @include md-max() {
        font-size:  toRem(12px);
      }
    }

    h1.jcms-html-title {
      align-items: flex-start;
      text-align: start;
      font-size: toRem(30px);
      font-weight: 700;
      color: var(--textPrimary);
      font-family: var(--font-family);
      margin: 0;
      @include md-max() {
        font-size: toRem(20px);
      }

    }

    span.jcms-html-subtitle {
      align-items: flex-start;
      text-align: start;
      color: var(--textPrimary);
      font-size:  toRem(18px);
      font-weight: 400;
      @include md-max() {
        font-size:  toRem(14px);
      }
    }

  }

  .card-rightSide {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    gap: 1rem;

    @include md-max() {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }

    @include sm-max() {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 2rem;
    }

    .btn-more-info {
      button {
        width: auto;
        min-width: toRem(205px);
        height: toRem(48px);
        background: var(--white);
        @include md-max() {
          min-width: toRem(190px);
          height: toRem(44px);
        }

        span{
          font-family: var(--font-family);
          color: var(--textPrimary)!important;
          font-size: toRem(20px);
          font-weight: 600;
        }

        &:hover {
          background: var(--white);
          span{
            color: var(--textPrimary)!important;
          }
        }
      }
    }

    .btn-purchase{
      button {
        width: auto;
        min-width: toRem(205);
        height: toRem(48px);
        border: unset;
        background: var(--primary);
        @include md-max() {
          min-width: toRem(190px);
          height: toRem(44px);
        }

        span{
          font-family: var(--font-family);
          color: var(--textInvert)!important;
          font-size: toRem(20px);
          font-weight: 600;
        }

        &:hover {
          background: var(--primary);
          span{
            color: var(--textInvert)!important;
          }
        }
      }
    }

  }
}