@import '../../../../style/media-variables';

.info-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  max-width: toRem(526px);
  width: auto;
  min-height: toRem(50px);
  height: auto;
  background: var(--warning-background);
  padding: 0 0.5rem;
  border-radius: 0.4375rem;

  @include sm-max(){
    padding: 2rem 1rem;
    max-width: toRem(310px);
  }

  .warning-img {
    color: var(--warning-color);
    width: toRem(24px);
    height: toRem(24px);
  }

  .jcms-html-text {
    span{
      font-size: toRem(14px);
      font-weight: 400;
      font-family: var(--font-family);
      color: var(--warning-color);
    }
  }
}