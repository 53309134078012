@import './../../style/media-variables.scss';

.vas-card-page{
  .vas-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 2rem;

    .vas-header-image{
      margin-bottom: 2rem;
    }
    .vas-header-cta{
      .jcms-cta-type-button {
        background: var(--white);
        width: toRem(171px);
        height: toRem(44px);
        @include sm-max(){
          width: toRem(139px);
          height: toRem(40px);
        }
        span {
          font-family: var(--font-family);
          color: var(--textPrimary);
          font-weight: 600;
          font-size: toRem(16px);
        }
        &:hover {
          background: var(--white);
          span {
            color: var(--textPrimary);
          }
        }
      }
    }
  }

  .slick-slider{
    .slick-track {
      justify-content: center!important;
    }
    @include sm-max(){
      .slick-list{
        height: auto!important;
        .slick-track {
          height: auto!important;
        }
      }
    }
    .slick-slide {
      width: auto!important;
      height: auto!important;
      @include sm-max(){
        width: auto!important;
        height: auto!important;
      }
    }
  }

  .vas-registration-cta {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      width: toRem(390px);
      height: toRem(48px);
      @include sm-max(){
        width: toRem(291px);
        height: toRem(44px);
      }
      span {
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 20px;
        @include sm-max(){
          font-size: 16px;
        }
      }
      &:hover {
        background: var(--primary);
      }
    }
  }
}

.subscription-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}